<!--
 * @Description: 完善信息
 * @Author: zhang zhen
 * @Date: 2023-02-06 14:30:31
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-24 15:14:01
 * @FilePath: /page-sass/src/views/PublishingRequirements/modules/completeInformation.vue
-->
<template>
  <div class="completeInformation">
    <div class="completeInformation-form">
      <h2 class="completeInformation-form-title">
        <span class="title">本次需求发布</span>
      </h2>
      <a-form-model
        ref="formC"
        :model="basicInfo3"
        :rules="rules3"
        class="basicInfo-form form-basic"
        :label-col="{
          span: 4,
        }"
        :wrapper-col="{
          span: 13,
        }"
        :colon="false"
      >
        <a-row :gutter="16" class="basicInfo-form-item">
          <a-col :span="24">
            <a-form-model-item label="需求名称" prop="purchaseTitle" :labelCol="{ span: 4 }" :wrapperCol="{ span: 20 }">
              <a-input
                placeholder="例如我需要一些飞机盒"
                v-model="basicInfo3.purchaseTitle"
                style="width: 750px; max-width: 100%"
              >
              </a-input>
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="basicInfo-form-item">
          <a-col :span="12">
            <a-form-model-item
              label="供应商参与数量"
              prop="participantCount"
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 16 }"
            >
              <a-select
                :getPopupContainer="getPopupContainer"
                placeholder="参与应价或报价的供应商数量"
                v-model="basicInfo3.participantCount"
              >
                <!-- suffixIcon -->
                <img src="/Polygon.png" alt="" class="icon-arrow-tag" slot="suffixIcon" />
                <a-select-option value>请选择</a-select-option>
                <a-select-option v-for="(item, key) in 20" :key="item" :value="item">
                  <span style="display: inline-block; width: 100%" :title="item"> {{ item }}个 </span>
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="需求类型" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }" prop="purchaseType">
              <j-purchasesSelect
                v-model="basicInfo3.purchaseType"
                dictCode="1043"
                placeholder="请选择"
                style="width: 100%; max-width: 320px"
                @backChange="handleCanBindData"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row :gutter="16" class="basicInfo-form-item">
          <a-col :span="12">
            <a-form-model-item
              label="报价截止日期"
              :rules="{
                required: true,
                message: '报价截止日期不能为空',
                trigger: ['change', 'blur'],
              }"
              :labelCol="{ span: 8 }"
              :wrapperCol="{ span: 16 }"
              prop="quotationDeadline"
            >
              <PDate
                placeholder="请选择"
                style="width: 100%"
                :removePast="true"
                v-model="basicInfo3.quotationDeadline"
              />
            </a-form-model-item>
          </a-col>
          <a-col :span="12">
            <a-form-model-item label="付款方式" prop="paymentMethod" :labelCol="{ span: 8 }" :wrapperCol="{ span: 16 }">
              <j-dictSelect
                v-model="basicInfo3.paymentMethod"
                dictCode="1044"
                placeholder="请选择"
                style="width: 100%; max-width: 320px"
              />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <h2 class="completeInformation-form-title" v-show="!hasFiled">
      <span class="title">采购信息</span>
    </h2>
    <a-form-model
      ref="formE"
      :model="basicInfo"
      style="width: 980px"
      class="basicInfo-form"
      :label-col="{
        span: 7,
      }"
      :wrapper-col="{
        span: 16,
      }"
      :colon="false"
      v-show="!hasFiled"
    >
      <a-row :gutter="26" class="basicInfo-form-item">
        <a-col :span="12">
          <a-form-model-item
            label="年包材需求总额"
            prop="packagingCostTotal"
            :rules="{ required: true, message: '年包材需求总额不能为空' }"
          >
            <j-dictSelect
              v-model="basicInfo.packagingCostTotal"
              dictCode="1013"
              placeholder="请选择"
              style="width: 320px; height: 38px"
            /> </a-form-model-item
        ></a-col>
        <a-col :span="12">
          <a-form-model-item
            label="要货方式"
            prop="purchasingMethod"
            :rules="{ required: true, message: '请选择要货方式' }"
          >
            <j-dictSelect
              v-model="basicInfo.purchasingMethod"
              dictCode="1005"
              placeholder="请选择"
              style="width: 100%; max-width: 320px"
            />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row :gutter="26" class="basicInfo-form-item">
        <a-col :span="12">
          <a-form-model-item
            label="供货区域"
            prop="providerArea"
            :rules="{ required: true, message: '请选择供货区域' }"
          >
            <a-row style="width: 100%; max-width: 328px" :gutter="10">
              <a-col :span="12">
                <j-dictSelect
                  v-model="basicInfo.providerArea"
                  dictCode="1048"
                  placeholder="请选择"
                  style="width: 100%"
                />
              </a-col>
              <a-col :span="12" v-if="basicInfo.providerArea == '1048001'">
                <j-dictSelect
                  v-model="basicInfo.providerAreaRegion"
                  dictCode="1007"
                  placeholder="请选择"
                  style="width: 100%"
                />
              </a-col>
            </a-row>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>
    <div class="completeInformation-form">
      <h2 class="completeInformation-form-title" style="margin-bottom: 0">
        <span class="title">散件(SKU)详细信息</span>
      </h2>
      <a-tabs v-model="formModel" @change="handleChangeMainTab">
        <a-tab-pane :key="1" tab="模版填写" :disabled="!!this.purchaseId"></a-tab-pane>
        <a-tab-pane :key="2" tab="直接填写" :disabled="!!this.purchaseId"></a-tab-pane>
      </a-tabs>
      <template v-if="formModel == 1">
        <a-form-model
          ref="formD"
          class="basicInfo-form"
          :label-col="{
            span: 3,
          }"
          :wrapper-col="{
            span: 13,
          }"
          :colon="false"
          :model="basicInfo3"
          style="margin-top: 10px"
        >
          <a-row :gutter="16" class="basicInfo-form-item">
            <a-col :span="16">
              <a-form-model-item
                label="包装材料"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 19 }"
                prop="wrapType"
                :rules="{ required: true, message: '请选择包装材料' }"
              >
                <j-multiSelectQuery
                  ref="SelectRef"
                  v-model="basicInfo3.wrapType"
                  :dictCode="basicInfo3.purchaseType"
                  :maxTagCount="4"
                  placeholder="请选择"
                  style="width: 560px"
                  @change="handleChangeExportPort"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="16">
              <a-form-model-item label="下载模版" :label-col="{ span: 3 }" :wrapper-col="{ span: 19 }" required>
                <a-input v-show="false" v-model="basicInfo3.templateFile"></a-input>

                <ExcelUploader ref="ExcelUploaderRef" @uploadFile="handleChangeModel" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24">
              <a-form-model-item label="上传附件" :label-col="{ span: 2 }" :wrapper-col="{ span: 19 }">
                <div class="uploadCover">
                  <div style="width: 334px">
                    <UploadDrag v-model="basicInfo3.planList" :number="10" />
                  </div>
                  <div class="endTitle">可上传工艺图纸、样品照片、3D模型图等,支持多种文件格式， 最多上传10个附件</div>
                </div>
              </a-form-model-item>
            </a-col>
            <a-col :span="16">
              <a-form-model-item
                class="requirement-label"
                label="其他需求"
                :label-col="{ span: 3 }"
                :wrapper-col="{ span: 19 }"
              >
                <a-textarea
                  placeholder="请输入具体的质量要求，检测要求或制造工艺要求等"
                  allow-clear
                  v-model="basicInfo3.otherRequirements"
                  :autosize="{ minRows: 4, maxRows: 4 }"
                  :maxLength="100"
                />
                <span class="text-length"
                  >{{ basicInfo3.otherRequirements ? basicInfo3.otherRequirements.length : 0 }}/100</span
                >
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </template>
      <template v-else>
        <div>
          <a-tabs
            v-model="activeIndex"
            type="editable-card"
            class="max-tabs"
            :tabBarGutter="0"
            @change="handleChangeTab"
            @edit="onEdit"
            hideAdd
          >
            <template #tabBarExtraContent>
              <a-button type="primary" icon="plus" ghost @click="onEdit(1, 'add')">添加需求单</a-button>
            </template>
            <a-tab-pane
              v-for="(i, index) in formList"
              :tab="
                i.purchaseTypeDictName && i.wrapTypeDictName
                  ? `${i.purchaseTypeDictName} - ${i.wrapTypeDictName}`
                  : `需求单${index + 1}`
              "
              :key="index"
              :closable="true"
              force-render
            >
              <a-form-model
                :ref="`settlementInfoForm${index}`"
                :model="i"
                :colon="false"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                style="width: 960px"
              >
                <!-- 动态生成表单 -->
                <a-row type="flex" justify="space-between" :gutter="40" class="completeInformation-form-newItem">
                  <a-col :span="24">
                    <splitLine label="包装材料" />
                  </a-col>
                  <a-col :span="12">
                    <a-form-model-item
                      label="包装材料"
                      :label-col="{ span: 8 }"
                      :wrapper-col="{ span: 16 }"
                      prop="wrapType"
                      :rules="{
                        required: true,
                        message: `包装材料不能为空`,
                        trigger: ['blur', 'change'],
                      }"
                    >
                      <j-dictSelectQuery
                        v-model="i.wrapType"
                        :dictCode="basicInfo3.purchaseType"
                        placeholder="请选择"
                        style="width: 100%"
                        @backChange="handleChangeFormMaterial($event, index)"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="item.span" v-for="(item, index) in i.forms" :key="item.itemId">
                    <!-- 目前只提供了省市区是独立的 -->
                    <a-form-model-item
                      :label="item.itemConfig.componentLabel"
                      :prop="'forms.' + index + '.filedValue'"
                      :rules="{
                        required: item.itemConfig.requiredFlag == '1',
                        message: `${item.itemConfig.componentLabel}不能为空`,
                        trigger: 'blur',
                      }"
                      :labelCol="{ span: item.labelColSpan }"
                      :wrapperCol="{ span: item.wrapperColSpan }"
                    >
                      <component
                        :is="formLabelDict[item.itemConfig.componentType]"
                        :dictCode="item.itemConfig.dictNo"
                        :placeholder="item.itemConfig.placeholder"
                        :label="item.itemConfig.componentLabel"
                        :textLength="item.itemConfig.textLength"
                        :contentType="item.itemConfig.contentType"
                        v-model="item.filedValue"
                      ></component>
                    </a-form-model-item>
                  </a-col>
                </a-row>
                <!-- 动态生成表单结束 -->
              </a-form-model>
            </a-tab-pane>
          </a-tabs>
        </div>
      </template>
      <a-row>
        <a-col :span="24" style="text-align: center; margin: 30px 0 20px">
          <a-button @click="handleCallBack" style="width: 120px; height: 38px; font-weight: 500; color: #262626"
            >取消</a-button
          >
          <!-- :loading="loading" -->
          <a-button
            type="primary"
            @click="handleSubmitData"
            :disabled="formModel == 2 && !formList.length"
            style="width: 120px; height: 38px; font-weight: 500; margin-left: 8px"
            >确认预览</a-button
          >
        </a-col>
      </a-row>
    </div>
    <a-modal title="温馨提示" :visible="showInfo" centered @ok="handleChangeTemplate" @cancel="handleCloseTemplate">
      <div class="info-card">
        <img src="/warning.png" alt="" />
        <span>您选择了与之前不同的需求类型，继续操作将会清空之前所有散件信息。确定选择新的需求类型吗?</span>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { PDate } from '@/components/easyComponents'
import JDictSelect from '@/components/plugins/JDictSelect.vue'
import JPurchasesSelect from '@/components/plugins/JPurchasesSelect.vue'
import JDictSelectQuery from '@/components/plugins/JDictSelectQuery.vue'
import JMultiSelectQuery from '@/components/plugins/JMultiSelectQuery.vue'
import JMultiSelectTag from '@/components/plugins/JMultiSelectTag.vue'
import UploadDrag from '@/components/plugins/uploadDrag'
import { mapGetters, mapActions, mapState } from 'vuex'
import { postAction, getAction, postQueryAction, downFiles } from '@/api/manage'
import ExcelUploader from './uploadFIle.vue'
import textInput from './components/textInput.vue'
import radioDict from './components/radioDict.vue'
import selectMultiple from './components/selectMultiple.vue'
import selectSingle from './components/selectSingle.vue'
import datePicker from './components/datePicker.vue'
import dateTimePicker from './components/dateTimePicker.vue'
import provincePicker from './components/provincePicker.vue'
import UploadFile from './components/UploadFile.vue'
import sizeInput from './components/sizeInput.vue'
import splitLine from './components/splitLine.vue'
import TextArea from './components/textArea.vue'
export default {
  name: 'completeInformation',
  components: {
    JPurchasesSelect,
    PDate,
    JDictSelect,
    JDictSelectQuery,
    UploadDrag,
    JMultiSelectTag,
    JMultiSelectQuery,
    ExcelUploader,
    dateTimePicker,
    datePicker,
    selectSingle,
    selectMultiple,
    radioDict,
    textInput,
    provincePicker,
    UploadFile,
    sizeInput,
    splitLine,
    TextArea,
  },
  props: {
    formType: {
      type: String,
      default: '',
    },
    purchaseId: {
      type: String,
    },
    hasMore: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showInfo: false,
      tradeIdentityList: [
        {
          label: '下载模板填写',
          value: 1,
        },
        {
          label: '直接填写',
          value: 2,
        },
      ],
      chosePurchaseType: '',
      purchaseTypeDictName: null,
      activeItem: {},
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      hadEditTab: false,
      loading: false,
      form: {},
      rules: {},
      formModel: 1,
      formLabelDict: {
        CHECKBOX: 'selectMultiple',
        TEXT: 'textInput',
        SELECT_SINGLE: 'selectSingle',
        SELECT_MULTIPLE: 'selectMultiple',
        DATE_TIME: 'dateTimePicker',
        DATE: 'datePicker',
        PROVINCE: 'provincePicker',
        RADIO: 'radioDict',
        FILE: 'UploadFile',
        WHD: 'sizeInput',
        SPLIT: 'splitLine',
        TEXTAREA: 'TextArea',
      },
      formSetting: {
        CHECKBOX: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16,
        },
        TEXT: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16,
        },
        SELECT_SINGLE: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16,
        },
        SELECT_MULTIPLE: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16,
        },
        DATE_TIME: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16,
        },
        DATE: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16,
        },
        PROVINCE: {
          span: 24,
          labelColSpan: 4,
          wrapperColSpan: 16,
        },
        RADIO: {
          span: 12,
          labelColSpan: 8,
          wrapperColSpan: 16,
        },
        FILE: {
          span: 19,
          labelColSpan: 5,
          wrapperColSpan: 19,
        },
        TEXTAREA: {
          span: 24,
          labelColSpan: 4,
          wrapperColSpan: 20,
        },
        WHD: {
          span: 24,
          labelColSpan: 4,
          wrapperColSpan: 16,
        },
        SPLIT: {
          span: 24,
          labelColSpan: 0,
          wrapperColSpan: 24,
        },
      },
      basicObj: {
        boxShape: '',
        boxType: '',
        budget: '',
        circulationRoute: '',
        colorPrinting: false,
        contents: '',
        corrugatedLayers: '',
        corrugatedType: '',
        dynamicStackLayers: '',
        expandedSize: '',
        expectedDeliveryDate: '',
        expectedDeliveryRegion: '',
        forming: '',
        grade: '',
        grammage: '',
        internalProtection: '',
        loadingTool: '',
        logisticsMonitoring: '',
        manufacturingSize: '',
        maxWeight: '',
        otherRequirements: '',
        packagingQuantity: '',
        packagingStatus: '',
        printingColor: '',
        printingMethod: '',
        printingRequirements: '',
        productSize: '',
        productStructure: '',
        productWeight: '',
        purchaseId: '',
        purchaseItemId: '',
        quotationDeadline: '',
        scatteredName: '',
        specialProtection: '',
        staticStackLayers: '',
        transportationMode: '',
        uploadFile: '',
        usageCount: '',
        waveGlue: false,
        planList: [],
      },
      basicInfo3: {
        purchaseTitle: '',
        packagingType: [],
        participantCount: undefined,
      },
      rules3: {
        purchaseTitle: { required: true, message: '请输入需求名称' },
        packagingType: { required: true, message: '请选择包装类别' },
        productType: { required: true, message: '请选择产品分类' },
        participantCount: {
          required: true,
          message: '请输入参与供应商的数量',
        },
        description: { required: true, message: '请输入产品的使用场景及产品的规格' },
        paymentMethod: { required: true, message: '请选择付款方式' },
        purchaseType: { required: true, message: '请选择需求类型' },
        priceType: { required: true, message: '请选择价格类型' },
        printProcess: { required: true, message: '请选择表面覆膜' },
      },
      activeIndex: 0,
      formList: [],
      packageList: [],
      keyRow: {
        product: 0,
        package: 1,
        plan: 2,
      },
      getPopupContainer: (node) => node.parentNode,
      expectedDeliveryProvince: [],
      expectedDeliveryCityList: [],
      expectedDeliveryDistrictList: [],
      baseFormItemList: [],
      basicInfo: {},
      roleType: 'BUSINESS',
      copyBaseData: {},
      importFileForm: [],
      hasFiled: false,
    }
  },
  computed: {
    ...mapState({
      packagingMaterial: (state) => state.packagingMaterial,
    }),
  },
  // watch: {
  //   packagingMaterial: {
  //     handler(newVal) {
  //       if (newVal) {
  //         this.handleCreateNewFiled();
  //       }
  //     },
  //     immediate: true
  //   }
  // },
  created() {
    this.activeIndex = 0
    this.hadEditTab = false
    this.handleInitDict(true)
    const { roleType } = this.setUserInfo()
    this.roleType = roleType
    this.handleLoadSaleManInfo()
  },
  methods: {
    ...mapActions(['asyncChangeStatus', 'asyncChangePackagingMaterial']),
    handleChangeImage(e, index) {
      if (e[0]) {
        this.formList[index].file = e[0].srcFileName
      } else {
        this.formList[index].file = null
      }
      this.$forceUpdate()
      console.log(this.$refs[`settlementInfoForm${this.activeIndex}`][0])
      this.$refs[`settlementInfoForm${this.activeIndex}`][0].clearValidate('file')
    },
    handleLoadSaleManInfo() {
      postQueryAction('/business/queryByBusinessId', {
        businessId: this.setUserInfo().businessId,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this.copyBaseData = { ...data }
          const { tradeIdentity, supplierPurchasePlan } = data
          supplierPurchasePlan && (this.basicInfo = Object.assign({}, supplierPurchasePlan))
          this.hasFiled = tradeIdentity != 0 || !!supplierPurchasePlan
        }
      })
    },
    ...mapGetters(['setUserInfo']),
    checkNegative(e, form, index, key) {
      var license_num = e.target.value
      license_num = license_num.replace(/[^\d]/g, '') // 清除“数字”和“.”以外的字符
      if (license_num.indexOf('.') < 0 && license_num != '') {
        // 以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        form[index][key] = parseInt(license_num)
      }
      form[index][key] = license_num
      this.$forceUpdate()
    },
    loadRegionList() {
      getAction('/area/queryByLevel', {
        level: 1,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this.expectedDeliveryProvince = data
        }
      })
    },
    changeInfo(e) {
      this.formList[this.activeIndex].expectedDeliveryProvince = e
      this.handleUpdateView()
      this.handleChangeRootId()
    },
    handleChangeTab() {
      this.expectedDeliveryCityList = []
      this.expectedDeliveryDistrictList = []
      this.formList[this.activeIndex].expectedDeliveryProvince && this.handleChangeRootId(true)
      this.formList[this.activeIndex].expectedDeliveryDistrict && this.handleLoadCity('deliveryDistrict', true)
    },
    handleChangeRootId(flag = false) {
      this.expectedDeliveryCityList = []
      if (!flag) {
        this.deliveryDistrictList = []
        this.formList[this.activeIndex].expectedDeliveryCity = undefined
        this.formList[this.activeIndex].expectedDeliveryDistrict = undefined
      }
      this.handleLoadCity('expectedDeliveryCity', flag)
      this.formList[this.activeIndex].expectedDeliveryDistrict && this.handleLoadCity('expectedDeliveryDistrict', flag)
    },
    handleUpdateView() {
      this.$forceUpdate()
    },
    handleLoadCity(key, flag = false) {
      let parentId = ''
      if (key == 'expectedDeliveryCity') {
        this.expectedDeliveryCityList = []
        this.expectedDeliveryDistrictList = []
        !flag && (this.formList[this.activeIndex].expectedDeliveryCity = undefined)
        parentId = this.formList[this.activeIndex].expectedDeliveryProvince
      }
      if (key == 'expectedDeliveryDistrict') {
        this.deliveryDistrictList = []
        !flag && (this.formList[this.activeIndex].expectedDeliveryDistrict = undefined)
        parentId = this.formList[this.activeIndex].expectedDeliveryCity
      }
      getAction('/area/queryByParentIdList', {
        parentId,
      }).then((res) => {
        const { success, data } = res
        if (success) {
          this[`${key}List`] = data
        }
      })
    },
    handleInitDict(needInit = false) {
      getAction('/material/category/queryByType', { categoryType: '2' }).then((res) => {
        const { data, success } = res
        success && data && (this.packageList = data)
      })
      // this.handleCreateNewFiled() // 表单字段会显示
    },
    handleCreateFileList() {
      return
      this.importFileForm = []
      getAction('/v2/purchases/queryEffectiveVersionBatch', {
        wrapType: this.basicInfo3.wrapType,
        purchaseType: this.basicInfo3.purchaseType,
      }).then((res) => {
        const { success, data, message } = res
        const { id, wrapType, items } = data
        if (success) {
          // items.map(i => {
          //   if (i.componentType == "FILE") {
          //     this.importFileForm.push({
          //       itemConfig: i,
          //       itemId: i.id,
          //       itemValue: "",
          //       itemValueDictText: "",
          //       purchaseId: "",
          //       versionId: id,
          //       versionItemId: i.versionItemId || '',
          //       wrapType,
          //       ...this.formSetting[i.componentType]
          //     })
          //   }
          // })
        } else {
          this.$message.warning(message)
        }
      })
    },
    // 生成表单字段
    handleCreateNewFiled(wrapType, index) {
      if (!this.basicInfo3.purchaseType) return
      // 发布的时候选择需求的包装材料
      getAction('/v2/purchases/queryEffectiveVersion', {
        wrapType,
        purchaseType: this.basicInfo3.purchaseType,
      }).then((res) => {
        const { success, data, message } = res
        const { id, wrapType, items } = data
        this.asyncChangePackagingMaterial(null)
        if (success) {
          this.baseFormItemList = items.map((i) => {
            let wrapperContent = this.formSetting[i.componentType]
            if (i.componentType == 'TEXT' && i.componentLabel.includes('地址')) {
                wrapperContent = {
                    span: 24,
                    labelColSpan: 4,
                    wrapperColSpan: 20,
                }
              }
            return {
              itemConfig: i,
              id: i.id,
              filedValue: '',
              textValue: '',
              purchaseId: '',
              formVersionId: id,
              purchaseItemId: '',
              wrapType,
              ...wrapperContent
            }
          })
          // this.handleAddRow()
          // this.
          this.formList[index]['forms'] = this.baseFormItemList
        } else {
          this.$message.warning(message)
        }
      })
      // }
    },
    // 检验时间
    checkDateTime(rule, value, callback) {
      const { expectedDeliveryDate } = this.formList[this.activeIndex]
      if (!value) {
        callback(new Error('报价截止日期不能为空!'))
      } else if (expectedDeliveryDate && value >= expectedDeliveryDate) {
        callback(new Error('报价截止日期不能晚于期望收货日期!'))
      } else {
        callback()
      }
    },
    handleChangeModel({ fileUrl, fileId }) {
      this.basicInfo3.templateFile = fileUrl
      this.$forceUpdate()
    },
    checkTemplate(rule, value, callback) {
      const { templateFile } = this.basicInfo3
      if (!templateFile) {
        callback(new Error('请上传下载模板'))
      } else {
        callback()
      }
    },
    checkFile(rule, value, callback) {
      if (!this.formList[this.activeIndex]['file']) {
        callback(new Error('请上传设计方案!'))
      } else {
        callback()
      }
    },
    checkExpectedDeliveryDate(rule, value, callback) {
      // const { quotationDeadline } = this.formList[this.activeIndex]
      if (!value) {
        callback(new Error('期望收货日期不能为空!'))
      } else if (this.basicInfo3.quotationDeadline && value <= this.basicInfo3.quotationDeadline) {
        callback(new Error('期望收货日期不能早于基础信息里面的止日期!'))
      } else if (!this.basicInfo3.quotationDeadline) {
        this.$refs.formC.validateField('quotationDeadline')
        this.formList[this.activeIndex]['expectedDeliveryDate'] = ''
      } else {
        callback()
      }
    },
    handleAddRow() {
      this.formList.push([...this.baseFormItemList])
    },
    /* 删除tab */
    handleChangeFormList(index) {
      if (this.formList.length == 1) return this.$message.warning('当前需求单不能删除，至少保留一个需求单')
      this.formList.splice(index, 1) // 删除
      setTimeout((_) => (this.activeIndex = 0), 300)
    },
    /* 回显示表单数据 */
    handleInitForm() {
      this.handleLoadSaleManInfo()
      if (!this.purchaseId) return // 拦截
      let params = {};
      if (this.$route.query.orderNo) {
        getAction('/order/queryByOrderNo', {
          orderNo: this.$route.query.orderNo,
        }).then((res) => {
          const { success, data } = res;
          if (success) {
            const { orderItems } = data
            // 遍历数组
            for (let element of orderItems) {
              const { wrapType, skuPrice } = element;
              params[wrapType] = skuPrice
            }
            this.handleEmitBasicData(params, true)
          }
        })
      } else {
        this.handleEmitBasicData(params, false)
      }
    },
    handleEmitBasicData(obj, flag) {
      postAction(`/v2/purchases/showPurList/${this.purchaseId}`).then((res) => {
        const { success, data, message } = res
        if (success) {
          const {
            itemList,
            templateFile,
            uploadFile,
            purchaseType,
            purchaseTitle,
            participantCount,
            quotationDeadline,
            paymentMethod,
            purchaseMode,
          } = data
          this.formModel = itemList && itemList.length ? 2 : 1
          if (itemList && itemList.length) {
            this.formList = itemList.map((i) => {
              const { itemForms, wrapType, wrapTypeDictName, formVersionId } = i
              let forms = itemForms.map((item) => {
                const { componentType, fieldFlag, componentLabel } = item
                let params =  {};
                if (flag && fieldFlag == 'price') {
                  params.filedValue = obj[wrapType]
                }
                let wrapperContent = this.formSetting[componentType]
                if (componentType == 'TEXT' && componentLabel.includes('地址')) {
                  wrapperContent = {
                      span: 24,
                      labelColSpan: 4,
                      wrapperColSpan: 20,
                  }
                }
                return {
                  ...item,
                  itemConfig: item,
                  ...wrapperContent,
                  ...params
                }
              })
              return {
                forms,
                formVersionId,
                purchaseTypeDictName: data.purchaseTypeDictName || '',
                wrapType: wrapType || '',
                wrapTypeDictName: wrapTypeDictName || '',
              }
            })
            this.purchaseTypeDictName = data.purchaseTypeDictName || ''
            this.handleChangeTab()
          }

          this.basicInfo3 = {
            purchaseType,
            templateFile,
            uploadFile,
            purchaseTitle,
            participantCount,
            quotationDeadline,
            paymentMethod,
            purchaseMode,
          }
        } else {
          this.$message.warning({
            content: (h) => <span style="margin-left: 9px; font-weight: 500;color: #262626;">{`${message}`}</span>,
            icon: (h) => <img src="./noPass.png" width="21" height="21" />,
          })
        }
      })
    },
    /* 存放参数 */
    handleUpLoadInput(value) {
      console.log(value)
      let targetItem = this.packageList.find((i) => i.categoryId == value)
      if (targetItem) {
        const { categoryName } = targetItem
        this.basicInfo3['packagingTypeName'] = categoryName
        this.$forceUpdate()
      }
    },
    handleChangeExportPort() {
      const { wrapType, purchaseType } = this.basicInfo3
      if (!wrapType || !purchaseType) return
      this.$refs.ExcelUploaderRef.handleInit({
        wrapType,
        purchaseType,
      })
      // this.handleCreateFileList() // 生成表单
    },
    handleSubmitData() {
      let formArr = this.hasFiled ? ['formC'] : ['formC', 'formE']
      if (this.formModel != 1) {
        this.formList.forEach((item, index) => {
          formArr.push('settlementInfoForm' + index)
        })
      } else {
        if (!this.basicInfo3.templateFile) {
          return this.$message.warning('下载模版不能为空')
        }
        formArr.push('formD')
      }

      let resultArr = [] //用来接受返回结果的数组
      const _self = this
      // formName 为ref名称
      function checkForm(formName) {
        //封装验证表单的函数
        var result = new Promise(function (resolve, reject) {
          ;(_self.$refs[formName][0] ? _self.$refs[formName][0] : _self.$refs[formName]).validate((valid) => {
            if (valid) {
              resolve()
            } else {
              reject()
            }
          })
        })
        resultArr.push(result) //push 得到promise的结果
      }
      console.log(formArr, 'formArr')
      // 批量校验
      formArr.forEach((item) => {
        //根据表单的ref校验
        checkForm(item)
      })
      const that = this
      // 判断校验是否完成
      Promise.all(resultArr)
        .then((values) => {
          this.loading = true
          let formData = [],
            baseInfo = { ...this.basicInfo3 },
            params = {}
          if (this.formModel != 1) {
            let hasNoField = this.formList.some((i) => !i.wrapType)
            if (hasNoField) {
              return this.$message.warning('请检查直接填写的表单是否填写完整！')
            }
            formData = this.formList.map((i) => {
              let wrapTypeValue = i.wrapType || '',
                formVersionIdValue = i.formVersionId || ''
              let itemForms = i.forms.map((item) => {
                const { itemConfig, wrapType, formVersionId } = item
                wrapType && (wrapTypeValue = wrapType)
                formVersionId && (formVersionIdValue = formVersionId)
                const {
                  componentLabel,
                  componentType,
                  requiredFlag,
                  dictNo,
                  deleteFlag,
                  placeholder,
                  textLength,
                  fieldFlag,
                  id,
                  contentType,
                  purchaseItemId,
                } = itemConfig
                let items = { ...item }
                items.itemConfig && delete items.itemConfig
                delete items.labelColSpan
                delete items.span
                delete items.labelColSpan
                delete items.wrapperColSpan
                delete items.wrapType
                delete items.formVersionId
                return {
                  ...items,
                  componentLabel,
                  componentType,
                  requiredFlag,
                  dictNo,
                  deleteFlag,
                  placeholder,
                  textLength,
                  fieldFlag,
                  id,
                  contentType,
                  purchaseItemId,
                }
              })
              return {
                itemForms,
                wrapType: wrapTypeValue,
                formVersionId: formVersionIdValue,
              }
            })
            baseInfo.planList && delete baseInfo.planList
            baseInfo.uploadFile = ''
            baseInfo.templateFile = ''
          } else {
            if (!this.basicInfo3.templateFile) {
              return this.$message.warning('模板不能为空')
            }
            if (this.importFileForm.length) {
              // 处理附件
              // if (this.importFileForm[0].itemConfig.requiredFlag == '1' && !this.basicInfo3.planList) {
              //   return this.$message.warning('附件不能为空')
              // }
            }
            this.basicInfo3.planList &&
              this.basicInfo3.planList.length &&
              (baseInfo.uploadFile = this.basicInfo3.planList.map((item) => item.url || '').toString())
            baseInfo.planList && delete baseInfo.planList
          }
          if (this.hasFiled) {
            this.handleSavePurchase(baseInfo, params, formData)
          } else {
            postAction('/business/save/supplierPurchasePlan', {
              ...this.basicInfo,
              userId: this.setUserInfo().userId,
              isSupplierPurchasePlan: true,
              businessId: this.setUserInfo().businessId,
            })
              .then((res) => {
                const { message, success } = res
                if (success) {
                  this.handleSavePurchase(baseInfo, params, formData)
                } else {
                  this.$message.warning(message)
                }
              })
              .catch((e) => {
                console.log(e)
                this.loading = false
              })
          }
        })
        .catch((res) => {
          console.log('error submit!!', res)
          that.loading = false
        })
    },
    handleSavePurchase(baseInfo, params, formData = []) {
      const that = this
      const { newOrder } = this.$route.query
      downFiles(this.formModel == '2' ? '/v2/purchases/save' : '/v2/purchases/file/save', {
        ...baseInfo,
        purchaseMode: baseInfo.purchaseMode || '',
        purchaseId: newOrder ? '' : this.purchaseId || '',
        ...params,
        skuItems: formData,
      })
        .then((res) => {
          console.log(res, 122)
          this.loading = false
          if (res.type == 'application/json') {
            // 创建一个 FileReader 对象
            let reader = new FileReader()
            // 当 FileReader 读取完 Blob 的内容后，调用此函数
            reader.onload = function (event) {
              // 将读取到的内容（文本）解析为 JSON 对象
              let jsonObj = JSON.parse(event.target.result)
              const { success, message, data } = jsonObj
              if (success) {
                that.$emit('ok', 'RequirementConfirmation')
                that.$emit('changePurchaseId', data || '')
              } else {
                message && that.$message.warning(message)
              }
            }

            // 读取 Blob 的内容为文本
            reader.readAsText(res)
          } else {
            this.$message.warning('当前模版有问题， 正在下载正确的模版')
            let url = window.URL.createObjectURL(new Blob([res], { type: 'application/vnd.ms-excel' }))
            let link = document.createElement('a')
            link.style.display = 'none'
            link.href = url
            let fileName = window.TemplateName || ''
            link.setAttribute('download', fileName)
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link) // 下载完成移除元素
            window.URL.revokeObjectURL(url) // 释放掉blob对象
            window.TemplateName = null // 清空数据
          }
        })
        .catch((e) => {
          console.log(e)
          this.loading = false
        })
    },
    handleCallBack() {
      this.$router.go(-1)
    },
    handleChangeMainTab(e) {
      this.basicInfo3.templateFile = []
      this.basicInfo3.planList = []
      if (e == 2) {
        // this.asyncChangeStatus(true);
        // this.formList = []
        // this.$nextTick(_ => {
        //   this.$refs.choosePackagingMaterialRef.handleInitData(this.basicInfo3.purchaseType)
        // })
        this.formList = [
          {
            forms: [],
          },
        ]
      }
    },
    /* 编辑 */
    onEdit(targetKey, action) {
      if (action == 'add') {
        // if (!this.basicInfo3.purchaseType) return this.$message.warning('请选择需求类型')
        this.formList.push({
          forms: [],
        })
        this.activeIndex = this.formList.length - 1
      } else {
        // choosePackagingMaterialRef
        this.handleChangeFormList(targetKey)
        this.activeIndex = this.formList.length - 1
      }
    },
    handleCanBindData(item) {
      this.activeItem = item
      if (this.formModel == '2') {
        let hasWrap = this.formList.find((i) => !!i.wrapType)
        if (hasWrap) {
          // 存在选中项目
          this.showInfo = true
        } else {
          this.handleChangePurchaseType(item)
        }
      } else {
        if (this.basicInfo3.wrapType) {
          // 存在选中项目
          this.showInfo = true
        } else {
          this.handleChangePurchaseType(item)
        }
      }
    },
    handleCloseTemplate() {
      this.basicInfo3.purchaseType = this.chosePurchaseType
      this.showInfo = false
    },
    handleChangeTemplate() {
      this.handleChangePurchaseType(this.activeItem)
      this.showInfo = false
    },
    handleChangePurchaseType(item) {
      this.basicInfo3.wrapType && delete this.basicInfo3.wrapType
      this.formModel == '1' && this.handleChangeExportPort()
      this.purchaseTypeDictName = item.label || ''
      this.chosePurchaseType = item.value || ''
      if (this.formModel == '2') {
        this.formList = [
          {
            purchaseTypeDictName: item.label || '',
            forms: [],
          },
        ]
      }
    },
    /* 切换需求类型 */
    handleChangeFormMaterial(info, index) {
      this.formList[index]['wrapTypeDictName'] = info.label || ''
      this.formList[index]['purchaseTypeDictName'] = this.purchaseTypeDictName || ''
      let packagingMaterial = this.formList[index]['wrapType']
      this.$forceUpdate()
      if (packagingMaterial) {
        this.handleCreateNewFiled(packagingMaterial, index)
      }
    },
  },
}
</script>

<style lang="less" scoped>
@import '~@/styles/form.less';

.completeInformation {
  &-form {
    &-title {
      font-size: 15px;
      color: #605f5f;
      margin-bottom: 30px;
      height: 38px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #efefef;

      &:before {
        background: #ff6026;
        display: inline-block;
        content: '';
        width: 5px;
        height: 16px;
        margin-right: 8px;
      }

      .title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #000;
        margin-right: 10px;
      }

      .subTitle {
        font-weight: 400;
        font-size: 14px;
        color: #8d8e99;

        ::v-deep .ant-radio-wrapper {
          color: #262626;
        }
      }
    }

    &-item {
      padding: 0 220px 0 4px;
    }

    &-newItem {
      padding-right: 60px;
    }
  }

  .chooseBtn {
    width: 184px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    cursor: pointer;
    position: relative;

    &.active {
      &::before {
        display: block;
        content: '';
        position: absolute;
        width: 16px;
        height: 6px;
        top: 4px;
        right: 6px;
        border-bottom: 1px solid #fff;
        border-left: 1px solid #fff;
        z-index: 12;
        transform: rotate(330deg);
      }

      &::after {
        // background: #009688;
        display: block;
        content: '';
        position: absolute;
        top: -1px;
        right: 0;
        border-top: 20px solid #009688;
        border-right: 20px solid #009688;
        border-bottom: 20px solid transparent;
        border-left: 20px solid transparent;
      }
    }
  }

  .endTitle {
    .flexLayout();
    font-size: 12px;
    justify-content: center;
    color: #8d8e99;
    margin-top: 8px;
  }

  ::v-deep .ant-tabs-left-content {
    padding-left: 30px;
    border-left: none;
  }

  .option-area {
    width: 139px;
    height: 38px;
    background: #fff5ee;
    border: 1px solid #ff6026;
    border-radius: 4px;
    padding: 8px 26px 8px 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 20px;
    top: 58px;
    z-index: 99;

    &-item {
      color: #ff6026;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      cursor: pointer;
    }

    .ant-divider-vertical {
      height: 14px;
      background-color: #ff6026;
      margin: 2px 10px 0;
    }
  }

  ::v-deep.ant-tabs {
    color: #131212;
  }

  .tabName {
    display: flex;
    align-items: center;

    .icons {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #c4c4c4;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 10px;
    }
  }

  .speLabel {
    // ::v-deep .ant-checkbox-inner {
    //   border-radius: 50%;
    // }
    // ::v-deep .ant-checkbox {
    //   border-radius: 50%;
    // }
    // ::v-deep .ant-checkbox-inner::after {
    //   left: 20%;
    // }
  }

  .NumberInfo {
    color: rgba(0, 0, 0, 0.45);
  }

  .hiddenTitle {
    ::v-deep .ant-form-item-label {
      visibility: hidden;
    }
  }

  .uploadCover {
    width: 560px;
    // min-height: 186px;
    padding: 32px 0 20px;
    background: #f9f9f9;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  ::v-deep.ant-form-item-label > label {
    color: #000;
  }

  .point {
    color: #000;
    text-align: center;
    line-height: 32px;
    font-size: 14px;
    margin-bottom: 16px;
  }

  .longLabel {
    overflow: visible;

    ::v-deep .ant-form-item-label-left {
      overflow: visible;
    }

    ::v-deep.ant-form-item-no-colon {
      margin-left: -20px;
    }
  }
}

// .has-error {
//   .uploadCover, .uploadView {
//     border: 1px solid #EE4261;
//   }
// }

:deep .ant-input-affix-wrapper.ant-input-affix-wrapper-textarea-with-clear-btn .ant-input {
  padding-right: 22px;
  // padding-top: 10px !important;
}

.max-tabs {
  margin-top: 10px;

  ::v-deep .ant-btn-background-ghost {
    background: #f2f3f5 !important;
    border-color: transparent;
    padding: 0 8px;
  }

  ::v-deep .ant-tabs-extra-content {
    padding-top: 7px;
    // width: 37px;
    display: flex;
    justify-content: center;
    align-items: center;

    .ant-tabs-new-tab {
      background: transparent;
      border: none;
    }
  }

  ::v-deep .ant-tabs-tab {
    border-radius: 0 !important;

    &:not(:nth-child(1)) {
      border-left: none !important;
    }
  }
}

::v-deep .form-basic {
  width: 890px;
}

.requirement-label {
  position: relative;

  .text-length {
    position: absolute;
    right: 15px;
    bottom: 15px;
    line-height: 14px;
    color: #00000073;
  }

}
::v-deep textarea.ant-input {
    line-height: 1.5!important;
  }
.info-card {
  font-size: 16px;
  font-weight: 500;
  color: #262626;
  display: flex;
  // align-items: center;

  img {
    margin-top: 2px;
    width: 21px;
    height: 21px;
  }

  span {
    margin-left: 10px;
  }
}
::v-deep .noPaddingLabel {
  margin-bottom: 0;
}

.ant-form-item-children {
  .split-line {
    margin-top: 0;
    margin-bottom: 0;
  }
}
</style>
